<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="项目信息编号">
          <el-input v-model="search.infono" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model="search.actname" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input v-model="search.itemname" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="tableData" stripe>
      <el-table-column prop="iteminfoid" label="项目信息编号"> </el-table-column>
      <el-table-column prop="actname" label="活动名称"> </el-table-column>
      <el-table-column prop="title" label="项目名称"> </el-table-column>
      <el-table-column label="活动起止时间" width="300px">
        <template slot-scope="scope">
          <span>{{ scope.row.begintime }} ~ {{ scope.row.endtime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="joinnum" label="允许参与人数"> </el-table-column>
      <el-table-column prop="count" label="已参与人数"> </el-table-column>

      <el-table-column label="操作" fixed="right" width="120" align="center">
        <template slot-scope="scope">
          <el-button @click="userlist(scope.row)" type="success">报名列表</el-button>
        </template>
      </el-table-column>
    </el-table>



    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>


    <!-- 报名列表 -->
    <el-dialog append-to-body title="报名列表" :close-on-click-modal='false' :visible.sync="applyUserFlag" v-if="applyUserFlag" width="70%">
      <applyuser-list :infoId="infoId"></applyuser-list>
    </el-dialog>
  </div>
</template>

<script>
import { listQuery } from "@/api/acitvity/index.js";
import applyuserList from '../applyUserList'
export default {
  name: "",
  components: {
    applyuserList
  },
  props: {
  },
  data() {
    return {
      tableData: [],
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      currentPage: 1,
      search: {
        actname: '',
        infono: '',
        itemname: ''
      },
      applyUserFlag: false,
      infoId: null,
      isList: 1
    };
  },
  watch: {},
  created() {
    this.getList();
  },
  mounted() {
    
  },
  computed: {},
  methods: {
    getList() {
      listQuery({
        userid: this.GLOBAL.adminId(),
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
        iteminfoid: this.search.infono,
        title: this.search.itemname,
        actname: this.search.actname,
        islist: this.isList,
      }).then((res) => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      });
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //搜索
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //查看报名列表
    userlist(row){
      this.applyUserFlag = true;
      this.infoId = row.iteminfoid;
    }
  },
};
</script>

<style lang="scss" scoped>
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
</style>